<template>
	<div class="box-style">
		<headerNav title="文档管理" leftNav="返回" @leftClick="back"></headerNav>
		<div class="content-style">
			<!-- 加载中 -->
			<div style="height: calc(100vh - 55px);display: flex;align-items: center;justify-content: center;" v-if="isLoading">
				<van-loading size="24px" vertical>加载中，请稍后...</van-loading>
			</div>
			<pullRefresh ref="pullRefresh" @refresh="onRefresh">
				<div class="pullRefresh-style">
					<div class="empty" v-if="!total">
						<img src="../../../../assets/uf23.png" alt="" style="width: 100%;">
						<div class="empty-text">暂无内容</div>
					</div>
					<van-list class="vant-list-style" v-model="loading" :finished="finished"
						:finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad" v-else>
						<div class="item-style" v-for="item in list">
							<div class="item-imgName" v-if="!['png', 'jpg', 'jpeg', 'gif'].includes(item.fileFormat)">
								<div class="item-imgName-img">
									<img :src="(imgs[item.fileFormat] || {}).imgUrl" alt="" style="width: 100%;height: 100%;">
								</div>
								<div class="item-imgName-right" :style="{ color: (imgs[item.fileFormat] || {}).color }">
									<div class="item-imgName-txt" @click="viewFile(item)">{{ item.fileName }}</div>
									<audio :src="item.fileAdd" controls style="height: 30px;margin-top: 8px;width: 100%;" v-if="item.fileFormat === 'mp3'"></audio>
								</div>
							</div>
							<div class="item-img" v-else>
								<imgPreview :src="item.fileAdd" />
							</div>
							<div class="item-text">
								<div class="item-label">上传时间：</div>
								<div class="item-value">{{ item.createDate }}</div>
							</div>
							<div class="item-text">
								<div class="item-label">备注信息：</div>
								<div class="item-value">{{ item.remark }}</div>
							</div>
						</div>
					</van-list>
				</div>
			</pullRefresh>
		</div>
		<upBtn v-if="total" />
	</div>
</template>

<script>
import api from '@/api/api.js'
import upBtn from './upBtn.vue'
import imgPreview from '@/components/imgPreview'
export default {
	components: {
		upBtn,
		imgPreview
	},
	data() {
		return {
			list: [],
			isLoading: false,
			loading: false,
			finished: false,
			error: false, // 是否加载失败
			pageNum: 1, // 分页
			pageSize: 10, // 每页条数
			total: 0, // 数据总条数
			imgs: {
				'dwg': { imgUrl: require('../../../../assets/uf7.png'), color: '#2E8E83' },
				'mp3': { imgUrl: require('../../../../assets/uf10.png'), color: '#B78BFF' },
				'doc': { imgUrl: require('../../../../assets/uf9.png'), color: '#56AAFF' },
				'docx': { imgUrl: require('../../../../assets/uf9.png'), color: '#56AAFF' },
				'xls': { imgUrl: require('../../../../assets/uf11.png'), color: '#4BB673' },
				'xlsx': { imgUrl: require('../../../../assets/uf11.png'), color: '#4BB673' },
			},
			info: {}
		}
	},
	methods: {
		back() {
			this.$router.push({ name: "deepenClicntDetailName", })
		},
		//获取列表
		async getList() {
			let obj = {
				Q_isDel_EQ: 0,
				Q_orderId_EQ: this.info.orderId
			}
			let res = await api.getFileManageList(obj)
			if (res.code == 200) {
				this.total = res.data.length;
				if (res.data.length === 0) { // 判断获取数据条数若等于0
					this.list = []; // 清空数组
					this.finished = true; // 停止加载
				} else {
					// 若数据条数不等于0
					this.list.push(...res.data) // 将数据放入list中
					// this.loading = false; // 加载状态结束 
					// 如果list长度大于等于总数据条数,数据全部加载完成
					if (this.list.length >= res.data.length) {
						this.finished = true; // 结束加载状态
					}
				}
				if (this.$refs.pullRefresh) {
					this.$refs.pullRefresh.refreshSuccess()
				}
			}
			this.isLoading = false
		},
		// 被 @load调用的方法
		onLoad() { // 若加载条到了底部
			let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
				// this.getList(); // 调用上面方法,请求数据
				this.getList()
				this.pageNum++; // 分页数加一
				this.finished && clearTimeout(timer); //清除计时器
			}, 100);
		},
		// 加载失败调用方法
		onRefresh() {
			this.finished = false; // 清空列表数据
			this.loading = true; // 将 loading 设置为 true，表示处于加载状态
			this.pageNum = 1; // 分页数赋值为1
			this.list = []; // 清空数组
			this.onLoad(); // 重新加载数据
		},
		// 查看文件
		viewFile(item){
			if(item.fileFormat !== 'mp3'){
				window.open(item.fileAdd)
			}
		}
	},
	mounted() {
		this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
		this.isLoading = true
		this.getList()
	},
}
</script>

<style scoped lang="scss">
.box-style {

	.content-style {
		overflow: auto;
		text-align: left;
		height: calc(100vh - 50px);

		.empty {
			min-height: calc(100vh - 90px);
			background: #fff;
			padding-top: 40px;

			.empty-text {
				text-align: center;
				font-size: 18px;
				font-family: PingFang SC, PingFang SC;
				color: #5E5E5E;
				margin-top: 10px;
				height: 100%;
			}
		}

		.item-style {
			margin: 0 8px;
			padding: 10px;
			background: #FFFFFF;
			border-radius: 4px;
			margin-top: 16px;

			.item-imgName {
				display: flex;

				.item-imgName-img {
					width: 32px;
					height: 32px;
				}

				.item-imgName-right {
					flex: 1;
					margin-left: 6px;
					font-size: 16px;
					color: #2E8E83;
					word-wrap: break-word;
					word-break: break-all;
					.item-imgName-txt {
						min-height: 24px;
						display: flex;
						align-items: center;
						padding-top: 4px;
					}
				}
			}

			.item-img {
				width: 100%;
				height: 140px;
				border-radius: 4px;
				overflow: hidden;
			}

			.item-text {
				margin-top: 6px;
				font-size: 15px;
				font-weight: 400;
				color: #333333;
				display: flex;
				font-size: 14px;

				&:nth-child(2) {
					margin-top: 12px;
				}

				.item-label {
					color: #888;
				}

				.item-value {
					flex: 1;
					word-wrap: break-word;
					word-break: break-all;
				}
			}

		}
	}
}
</style>
